import * as eth from '../services/ethereumService'
import { getSettleSpread } from '../services/helperService'
import { getConfig } from '../services/apiService/getConfig'
import type { AppDispatch } from '../store/configureStore'
import type { DVFConfig } from '../services/apiService/getConfig.types'
import { portalSlice } from '../reducers/portalSlice'
import { fetchTickers } from './tickerActions'
import { initCrossChain } from './crossChainSwapActions'

export const showGasPriceModal = (gasLimit: number, resolveId: string, EIP1559 = false) => {
  window.store.dispatch({
    type: 'ADD_TRANSACTION_CONFIRMATION',
    payload: {
      transactionConfirmation: {
        gasLimit,
        resolveId,
        EIP1559,
      },
    },
  })
}

export const checkIfProviderIsLate = (dispatch: AppDispatch) => async () => {
  try {
    const blocks = await eth.checkIfProviderIsLate()
    dispatch(portalSlice.actions.setPortalReducerProviderIsLate({ providerIsLate: blocks }))
  } catch (error) {}
}

const convertPools = <T extends { enabled: boolean }>(pools: Record<string, T>) => {
  let ammPools: Record<string, T> = {}
  Object.keys(pools).forEach((pool) => {
    if (pools[pool].enabled) {
      ammPools[pool] = pools[pool]
    }
  })
  return ammPools
}

export const prepareConfig = (config: DVFConfig) => {
  const { DVF, tokenRegistry, nonL2TokenRegistry, yieldMarkets, useItOrLoseItCampaign, bridgeRewards } = config
  const settleSpread = getSettleSpread(tokenRegistry)

  const ammPools = convertPools(config.ammPools)

  return {
    tokenRegistry,
    nonL2TokenRegistry,
    minDepositUSDT: DVF.minDepositUSDT,
    exchangeSymbols: DVF.exchangeSymbols,
    newMarkets: DVF.newMarkets,
    dlmMarkets: DVF.dlmMarkets,
    settleSpread,
    ammPools,
    yieldMarkets,
    useItOrLoseItCampaign,
    bridgeRewards,
    bridgeConfigPerChain: DVF.bridgeConfigPerChain,
  }
}

export const fetchConfig =
  (dispatch: AppDispatch) =>
  async ({ isAuthenticated, isFromInterval }: { isAuthenticated?: boolean; isFromInterval: boolean }) => {
    try {
      const config = await getConfig({ dispatch, isAuthenticated, isFromInterval })

      if (config === false) {
        throw new Error("Can't fetch config")
      }
    } catch (error) {
      return false
    }
  }

export const initConfig = (dispatch: AppDispatch) => async () => {
  try {
    const config = await getConfig({ dispatch })
    if (!config) {
      return false
    }

    initCrossChain(dispatch)()
    fetchTickers(dispatch)()

    return config
  } catch (error) {
    return false
  }
}
