import type { NFTType } from '../../../../services/nfts/mintNFT/mintNFT.types'
import alphaKey from './alpha-key.png'
import betaKey from './betakey.png'
import baseHunter from './base-hunter.png'
import baseProHunter from './base-pro-hunter.png'
import blastHunter from './blast-hunter.png'
import blastProHunter from './blast-pro-hunter.png'
import lineaHunter from './linea-hunter.png'
import lineaProHunter from './linea-pro-hunter.png'
import mantaHunter from './manta-hunter.png'
import mantaProHunter from './manta-pro-hunter.png'
import scrollHunter from './scroll-hunter.png'
import scrollProHunter from './scroll-pro-hunter.png'
import EXPLORER from './starknet-explorer.png'
import GOD from './starknet-god.png'
import zkEvmHunter from './zkevm-hunter.png'
import zkEvmProHunter from './zkevm-pro-hunter.png'
import zkEvmTxCount from './zkevm-txcount.png'
import zkEvmVolume from './zkevm-volume.png'
import zkSyncHunter from './zksync-hunter.png'
import zkSyncProHunter from './zksync-pro-hunter.png'
import zkSyncTxCount from './zksync-txcount.png'
import zkSyncVolume from './zksync-volume.png'
import zkSyncWrapped2023 from './zksync-wrapped-2023.png'
import taikoHunter from './taiko-hunter.png'
import taikoProHunter from './taiko-pro-hunter.png'
import taikoVolume from './taiko-volume.png'
import taikoTxCount from './taiko-txcount.png'
import zircuitHunter from './zircuit-hunter.png'
import inkHunter from './ink-hunter.png'

export const getNFTImage = (type: NFTType, chain: string): string => {
  const img = nftImages[chain][type]
  if (!img) {
    throw new Error(`No image found for ${type} on ${chain}`)
  }
  return img
}

const nftImages: Record<string, Partial<Record<NFTType, string>>> = {
  ZKSYNC: {
    hunter: zkSyncHunter,
    TOP30: zkSyncProHunter,
    wrapped2023: zkSyncWrapped2023,
    VOLUME: zkSyncVolume,
    TXCOUNT: zkSyncTxCount,
  },
  LINEA: {
    hunter: lineaHunter,
    TOP30: lineaProHunter,
  },
  SCROLL: {
    hunter: scrollHunter,
    TOP30: scrollProHunter,
    ALPHAKEY: alphaKey,
    BETAKEY: betaKey,
  },
  ZKEVM: {
    hunter: zkEvmHunter,
    TOP30: zkEvmProHunter,
    VOLUME: zkEvmVolume,
    TXCOUNT: zkEvmTxCount,
  },
  MANTA: {
    hunter: mantaHunter,
    TOP30: mantaProHunter,
  },
  STARKNET: {
    EXPLORER: EXPLORER,
    GOD: GOD,
  },
  BLAST: {
    hunter: blastHunter,
    TOP30: blastProHunter,
  },
  TAIKO: {
    hunter: taikoHunter,
    TOP30: taikoProHunter,
    VOLUME: taikoVolume,
    TXCOUNT: taikoTxCount,
  },
  ZIRCUIT: {
    hunter: zircuitHunter,
  },
  BASE: {
    hunter: baseHunter,
    TOP30: baseProHunter,
  },
  INK: {
    hunter: inkHunter,
  },
}
