import { TonConnectUI, toUserFriendlyAddress } from '@tonconnect/ui'
import { NETWORKS } from '../../../constants/types'
import { envConfig } from '../../../env/envConfig'
import { setupTonProvider, getTonProvider } from '../providers/tonProvider'

const { appURL, configEnv } = envConfig

export const connectToTon = async () => {
  const { walletProvider } = getTonProvider(true)
  const tonConnectUI =
    walletProvider ||
    new TonConnectUI({
      manifestUrl:
        import.meta.env.MODE === 'production'
          ? `https://${appURL}/tonconnect-manifest.json`
          : 'http://localhost:3000/tonconnect-manifest.json',
      buttonRootId: 'ton-connect',
    })
  const connectedWallet = await tonConnectUI.connectWallet()
  if (!connectedWallet) {
    throw new Error('Something went wrong when connecting your Tron wallet.')
  }
  setupTonProvider(tonConnectUI)
  const rawAddress = connectedWallet.account.address
  const address = toUserFriendlyAddress(rawAddress, configEnv !== 'production').toString()

  return {
    address,
    networkId: 'TON',
    domain: '',
    connectedChain: NETWORKS.TON,
  }
}
