import Aigle from '@rhino.fi/aigle'
import { flatMap } from 'lodash'
import type { EVMNFTType } from '../../../../../services/nfts/mintNFT/mintNFT.types'
import {
  getERC1155ContractConfig,
  getNFTContract,
  getNFTContractConfig,
} from '../../../../ActivityTracker/ActivityTrackerNFT/helpers/nftContractHelpers'
import { isOldZkSyncNFT } from '../../../../ActivityTracker/ActivityTrackerNFT/helpers/isOldZkSyncNFT'
import { NETWORKS } from '../../../../../constants/types'
import { NFTContractTypeSchema } from '../../../../../services/nfts/mintNFT/mintNFT.schemas'
import { getNFTMetaData } from './getNFTMetaData'
import { getZkSyncNFTs } from './getZkSyncNFTs'
import type { NFTMinter } from './NFTMinterAssets'
import { getStarknetNFTs } from './getStarknetNFTs'

const getUserTokenIds = async (
  type: EVMNFTType,
  chain: string,
  user: string,
): Promise<{ tokenId: number; index: number }[] | null> => {
  // zkSync contract does not have a getUserTokenIds method
  if (isOldZkSyncNFT(type, chain)) {
    return null
  }

  const contract = getNFTContract(type, chain, false)

  if (!contract) {
    return null
  }

  const contractConfig = getNFTContractConfig(chain, type)

  if (contractConfig.type === NFTContractTypeSchema.enums.RhinoERC1155) {
    const { tokenId } = getERC1155ContractConfig(chain, type)
    const hasMinted = await contract.methods.balanceOf(user, tokenId).call()
    // add tokenId to an array as many times as the user has minted it
    const result = Array.from({ length: Number(hasMinted) }, (_, index) => ({
      tokenId,
      index,
    }))
    return result
  }

  const userTokenIds = await contract.methods.getUserTokenIds(user).call()
  return userTokenIds.map((id: string, index: number) => ({
    tokenId: Number(id),
    index,
  }))
}

export const getNFTsForChain = async (chain: string, user: string): Promise<NFTMinter[]> => {
  try {
    if (chain === NETWORKS.ZKSYNC) {
      const hunterAndProHunter = await getZkSyncNFTs(user)

      const otherNFTs: Array<EVMNFTType> = ['wrapped2023', 'VOLUME', 'TXCOUNT']
      const otherIds = await Aigle.map(otherNFTs, (type) => getUserTokenIds(type, chain, user))
      const otherNFTsMetaData = otherIds.flatMap((ids, index) =>
        (ids || []).map((id) => getNFTMetaData(otherNFTs[index], chain, id.tokenId)),
      )

      return [...hunterAndProHunter, ...otherNFTsMetaData]
    }

    if (chain === NETWORKS.STARKNET) {
      const starknetNFTs = await getStarknetNFTs(user)
      return starknetNFTs
    }

    const nftTypes = ['hunter', 'TOP30', 'VOLUME', 'TXCOUNT', 'ALPHAKEY', 'BETAKEY'] as EVMNFTType[]
    const nftIDs = await Aigle.map(nftTypes, (type) => getUserTokenIds(type, chain, user))

    const nftsWithMetaData = nftIDs.map((nftIdsForType, index) =>
      (nftIdsForType || []).map((id) => getNFTMetaData(nftTypes[index], chain, id.tokenId, id.index)),
    )

    return flatMap(nftsWithMetaData)
  } catch (error) {
    console.error(`Failed to get NFTs for chain ${chain}`, error)
    return []
  }
}
