import keyBy from 'lodash/keyBy'
import { createSelector } from 'reselect'
import { pickBy, mapValues } from 'lodash'
import type { RootState } from '../../store/configureStore'
import { ccyNames } from '../../constants/symbols'
import { getObjectKeysOnly } from '../../services/helperService/getObjectKeysOnly'
import { yieldTokensConfigs } from '../../services/yieldService/yieldTokensConfigs'
import type { CrossChainSwapConfigForChain } from '../../services/apiService/getCrossChainSwapConfig'
import { getConfigKey } from '../../services/helperService'
import {
  selectCrossChainYieldRegistry,
  selectCrossChainTokenConfig,
  selectCrossChainTokenRegistry,
} from './xchainSelectors'

const emptyObjectDefault = {} as Record<string, CrossChainSwapConfigForChain>
export const selectExchangeSymbols = (state: RootState) => state.portal.exchangeSymbols
export const selectTokenRegistry = (state: RootState) => state.portal.tokenRegistry
export const selectNonL2TokenRegistry = (state: RootState) => state.portal.nonL2TokenRegistry
export const selectBridgeConfigPerChain = (state: RootState) => state.portal.bridgeConfigPerChain
export const selectSelectedTheme = (state: RootState) => state.portal.selectedTheme
export const selectCrossChainConfigPerChain = (state: RootState) =>
  state.xchain.crossChainSwapConfig?.configPerChain ?? emptyObjectDefault
export const selectHasBalanceForTransaction = (state: RootState) => state.portal.hasBalanceForTransaction
export const selectMinDepositUSDT = (state: RootState) => state.portal.minDepositUSDT
export const selectDLMMarkets = (state: RootState) => state.portal.dlmMarkets
export const selectDefaultRoute = (state: RootState) => state.portal.defaultRoute

export const selectFailedChainsObject = (state: RootState) => state.portal.failedChains
export const selectFailedChains = createSelector(selectFailedChainsObject, (failedChains) =>
  Object.keys(failedChains).filter((chain) => failedChains[chain]),
)

export const selectCrossChainYieldRegistryObject = createSelector(
  selectCrossChainYieldRegistry,
  (crossChainYieldRegistry) => keyBy(crossChainYieldRegistry, 'token'),
)
export const selectCrossChainTokenRegistryObject = createSelector(
  selectCrossChainTokenRegistry,
  (crossChainTokenRegistry) => keyBy(crossChainTokenRegistry, 'token'),
)

export const selectSupportedTokens = createSelector(
  selectCrossChainTokenRegistryObject,
  selectTokenRegistry,
  (crossChainRegistry, tokenRegistry) => {
    const crossChainTokens = getObjectKeysOnly(crossChainRegistry)
    const starkExTokens = getObjectKeysOnly(tokenRegistry)

    return { ...crossChainTokens, ...starkExTokens }
  },
)

export const selectDisplayInfoForToken = createSelector(
  selectCrossChainTokenConfig,
  (crossChainConfig) => (token: string) => {
    const tokenName = ccyNames[token] ?? crossChainConfig?.[token]?.name ?? ''

    return {
      name: tokenName,
    }
  },
)
export const selectEnabledAmmPools = (state: RootState) => pickBy(state?.portal?.ammPools, ({ enabled }) => enabled)
export const selectRequiredChain = (state: RootState) => state.portal.requiredChain
export const selectHasFetchedBalances = (state: RootState) => state.portal.exchangeBalanceFetched
export const selectPermissions = (state: RootState) => state.portal.permissions
export const selectUseItOrLoseItCampaign = (state: RootState) => state.portal.useItOrLoseItCampaign

export const selectBridgeRewards = (state: RootState) => {
  const enabledBridgeRewards = getConfigKey('enabledBridgeRewards')
  const bridgeRewardsFallback = state.portal.bridgeRewards || []

  return bridgeRewardsFallback.filter(({ id }) => enabledBridgeRewards.includes(id))
}

export const selectYieldMarkets = (state: RootState) => state.portal.yieldMarkets
export const selectYieldTokenNames = createSelector(selectYieldMarkets, (yieldMarkets) => {
  const candidates = mapValues(
    yieldTokensConfigs,
    (value, key) => `${key.toUpperCase()}${value.baseToken.toUpperCase()}`,
  )

  const yieldTokens = Object.keys(candidates).filter((key) => {
    return yieldMarkets?.[candidates[key]] ?? true
  })

  return yieldTokens
})
