import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { NationWidgetState } from '../types/nationWidgetSlice.types'
import { NATION_ALL_FILTER_OPTION_DATA } from '../constants/nationAllFilterOptionData.constants'
import { FEATURES_OPTIONS } from '../constants/nationWidgetFilterOptions.constants'
import type { NationWidgetMissionsFilterItemState } from '../types/nationWidgetMissionsFilterItem.types'

export const getInitialState = (): NationWidgetState => ({
  widgetOpen: false,

  chainOptions: [NATION_ALL_FILTER_OPTION_DATA],
  selectedChain: NATION_ALL_FILTER_OPTION_DATA.key,

  featureOptions: FEATURES_OPTIONS,
  selectedFeature: NATION_ALL_FILTER_OPTION_DATA.key,
})

export const nationWidgetSlice = createSlice({
  name: 'nationWidget',
  initialState: getInitialState(),
  reducers: {
    setNationWidgetVisibility(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        widgetOpen: action.payload,
      }
    },
    setNationWidgetChainOptions(state, action: PayloadAction<NationWidgetMissionsFilterItemState[]>) {
      return {
        ...state,
        chainOptions: action.payload,
      }
    },
    setNationWidgetSelectedChain(state, action: PayloadAction<string>) {
      return {
        ...state,
        selectedChain: action.payload,
      }
    },
    setNationWidgetSelectedFeature(state, action: PayloadAction<string>) {
      return {
        ...state,
        selectedFeature: action.payload,
      }
    },
    resetNationWidgetSelectedFilters(state) {
      return {
        ...state,
        selectedChain: getInitialState().selectedChain,
        selectedFeature: getInitialState().selectedFeature,
      }
    },
  },
})

export const {
  setNationWidgetVisibility,
  setNationWidgetSelectedChain,
  setNationWidgetSelectedFeature,
  setNationWidgetChainOptions,
  resetNationWidgetSelectedFilters,
} = nationWidgetSlice.actions
